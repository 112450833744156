import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import About from '../template/pages/About';
import SEO from "../components/SEO";

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          <About {...props} />
      </Layout>
    </>
}

export default Page;
